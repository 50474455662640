// import UserForm from "../../components/UserForm";

import UsersService from '../../services/UsersService';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Config from '../../config/environments/local';
import Button from '../../components/Button';
import { ButtonContainer } from './styles';
import Loader from '../../components/Loader';
import BigLogo from '../../assets/icons/menu/big-logo.svg'
import {
  // Container,
  // Header
} from './styles';
import NoPendingItems from '../../assets/images/img_success.png';
// import CheckEmail from '../../assets/images/check-email.png';

export default function UserConfirmation() {

  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isActivateUserSucess, setIsActivateUserSucess] = useState(false);

  async function handleGoToLogin() {
    window.open('/login', '_self');
  }

  async function activateUser() {

    const response = await new UsersService(Config.API_BASE_URL).ActivateUser({
      Code: code
    });

    if (response.Result === true) {
      setIsActivateUserSucess(true);
    }
    else {

    }

    setIsLoading(false);
  }

  if ((!isLoading) && (!isActivateUserSucess)) {
    setIsLoading(true);
    activateUser();
  }

  return (
    <>

      <Loader isLoading={isLoading} />

      <div className='basic-container'>
        <div className="big-logo-container smaller">
          <img src={BigLogo} alt='logo' />
        </div>
        <div className='image-container'>
          <img src={NoPendingItems} alt='logo' />
        </div>

        {(isActivateUserSucess) ? (
          <>
            <div className='regular-title'>
              Cadastro ativado com sucesso!
            </div>
            <div className='regular-text'>
              Faça seu login para continuar
            </div>

            {/* <div style={{ height: '100px' }}></div> */}

            <ButtonContainer
              style={{
                width: '100%',
                position: 'fixed',
                bottom: '24px',
              }}>
              <Button
                type="button"
                onClick={(e) => handleGoToLogin(e)}
              >
                Ir para login
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <div className='regular-title'>
              Ativação de cadastro
            </div>
            {/* <div className='regular-text'>
              Faça seu login novamente para continuar.
            </div> */}

            <div style={{ height: '27px' }}></div>

            {/* <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => handleGoToLogin(e)}
              >
                Ok, entendi
              </Button>
            </ButtonContainer> */}
          </>
        )}
      </div>
    </>
  )
};