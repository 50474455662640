// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
// import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'
import GoBackHeader from "../../components/GoBackHeader";
import NoNotifications from '../../assets/images/no-notifications-image.svg';
import Bell from '../../assets/icons/bell.svg';
import OrangeBell from '../../assets/icons/orange-bell.svg';
import Loader from "../../components/Loader";
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
import Calendar from '../../assets/icons/event.svg';
import AcceptShare from '../../assets/icons/accept-share.svg';
import DenyShare from '../../assets/icons/deny-share.svg';
import AppointmentsService from '../../services/AppointmentsService';
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import isAppleDevice from '../../utils/isAppleDevice';
// import moment from 'moment';

import 'moment/locale/pt-br'; // Importa a localização para português do Brasil

export default function Notifications() {
  const [homeViewModel, setHomeViewModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isIphone, setIsIphone] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [sharedAppointments, setSharedAppointments] = useState([]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  // const [isInstallable, setIsInstallable] = useState(false);
  const isInstalled = window.matchMedia("(display-mode: standalone)").matches;

  useEffect(() => {
    const device = isAppleDevice();
    setIsIphone((device === 'iPhone' || device === 'Mac'));
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent the default prompt
      setDeferredPrompt(event); // Save the event for triggering later
      console.log(event);
      // setIsInstallable(true); // Update state to show an install button
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        alert(choiceResult.outcome);
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Clear the prompt
      });
    }
  };

  const listAppointmentsToApprove = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllPendingOfApproval(sessionObject.userId);

      setSharedAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    listAppointmentsToApprove();
  }, [listAppointmentsToApprove]);

  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return null;
    }

    try {
      setIsLoading(true);

      const viewModel = await new AppointmentsService(Config.API_BASE_URL).GetNewHomeViewModel(
        {
          UserId: sessionObject.userId,
        }
      );

      setHomeViewModel(viewModel.Result);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    listAppointments();

    // listAppointments();
  }, [listAppointments]);

  async function handleApprove(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: true,
      });
      listAppointmentsToApprove();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRefuse(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: false,
      });
      listAppointmentsToApprove();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function markNotificationAsRead(notificationId) {
    try {
      setIsLoading(true);
      debugger;
      await new AppointmentsService(Config.API_BASE_URL).markAppointmentNotificationAsRead({
        Id: notificationId,
      });

      listAppointments();

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <GoBackHeader
        backAction={"/"}
        title={"Notificações"}
        mustShowInfoIcon={false}
      />
      <Loader isLoading={isLoading} />


      {
        ((homeViewModel && homeViewModel.Notifications && homeViewModel.Notifications.length > 0) ||
          (
            sharedAppointments && sharedAppointments.length > 0
          )) ? (
          <>
            <div className='basic-container no-padding'>
              {(isIphone && (!isInstalled)) && (
                <>
                  <div className='iphone-add-homescreen'>
                    <div className='left-side'>
                      <img src={OrangeBell} alt='profile' />
                    </div>
                    <div className='right-side'>
                      <div className='top-text'>
                        Adicione o TeLembro a sua tela inicial e receba notificações em tempo real
                      </div>
                      <div className='bottom-text'
                        onClick={(e) => {
                          handleInstallClick();
                        }}
                      >
                        Saiba mais
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(sharedAppointments && sharedAppointments.length > 0) && (
                <>
                  <div
                    className="cards-container notification"
                  >
                    <>
                      {sharedAppointments && (
                        sharedAppointments.map((sharedAppointment) => (
                          <>
                            <div
                              className="pending-text"
                            >
                              Convite para participar do compromisso:
                            </div>
                            <div className="card"
                              key={`apppointmentid-${sharedAppointment.Appointment.Id}`}
                              onClick={(e) => {
                                window.open(`/shared-messages/${sharedAppointment.Appointment.Id}`, '_self');
                              }}
                            >
                              <div className="top-part">
                                <div className="left">
                                  <div className="appointment-date">
                                    <img src={Calendar} alt='date' />
                                    {sharedAppointment.Appointment.AppointmentDate ? format(new Date(sharedAppointment.Appointment.AppointmentDate), 'dd/MM/yyyy') : ''}

                                    {sharedAppointment.Appointment.AppointmentTime && (
                                      ` - ${sharedAppointment.Appointment.AppointmentTime}`
                                    )}
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="appointment-type" style={{ background: sharedAppointment.Appointment.TagColorHex }}>
                                    {sharedAppointment.Appointment.AppointmentTypeName}
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-part">
                                <div className="title">
                                  {sharedAppointment.Appointment.Name && (
                                    <>{sharedAppointment.Appointment.Name}</>
                                  )}
                                </div>

                                <div className="observations">
                                  {sharedAppointment.Appointment.Observations && (
                                    <>{sharedAppointment.Appointment.Observations}</>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="actions">
                              <div
                                className="confirm"
                                onClick={(e) => {
                                  handleApprove(e, sharedAppointment.Id)
                                }}
                              >
                                <img src={AcceptShare} alt='date' />
                                Aceitar
                              </div>
                              <div
                                className="refuse"
                                onClick={(e) => {
                                  handleRefuse(e, sharedAppointment.Id)
                                }}
                              >
                                <img src={DenyShare} alt='date' />
                                Recusar
                              </div>
                            </div>
                          </>

                        ))
                      )}
                    </>
                  </div>
                </>
              )}

              <div className='appointments-notifications'>
                {(homeViewModel && homeViewModel.Notifications) && homeViewModel.Notifications.map((notification) => (
                  <div className={`appointment-notification ${notification.IsNew ? 'new' : ''}`}
                    data-id={notification.Id}
                    key={notification.Id}
                    onClick={() => {
                      markNotificationAsRead(notification.Id);
                    }}
                  >
                    <div className='notification-title'>
                      <img src={Bell} alt='profile' />
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      width: '100%'
                    }}>
                      <div className='notification-title'>
                        {notification.Title}
                      </div>
                      <div className='notification-observation'>
                        {notification.Description}
                      </div>
                      <div className='notification-link'>
                        <a href={`/shared-messages/${notification.AppointmentId}`}>
                          Ver compromisso
                        </a>
                      </div>
                    </div>
                    {(notification.IsNew === true) && (
                      <>
                        <div className='today-text'>
                          Novo!
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className='basic-container'>
            <>
              <div className='image-container'>
                <img src={NoNotifications} alt='profile' />
              </div>
              <div className='no-content-text'>
                Você ainda não possui nenhuma notificação
              </div>
              <div className='no-appointments'>
                Ao criar compromissos as atualizações aparecerão aqui
                <a href='/new'>
                  Criar compromisso +
                </a>
              </div>
            </>
          </div>
        )
      }

    </>
  )
};