import HttpClient from './utils/HttpClient';

class AppointmentsService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async listAppointments(userId, fetchOnlyActive) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all/${userId}/status/${fetchOnlyActive}`;

    return this.httpClient.get(urlPath);
  }

  async listAppointmentsNotShared(userId, fetchOnlyActive) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all-not-shared/${userId}/status/${fetchOnlyActive}`;

    return this.httpClient.get(urlPath);
  }

  async GetAppointmentTitlesByUserId(userId) {
    const urlPath = `/appointments/get-titles/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async GetAllFiltered(request) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all-filtered`;

    return this.httpClient.post(urlPath, request);
  }

  async GetAll(userId, fetchOnlyActive) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all/${userId}/status/${fetchOnlyActive}`;

    return this.httpClient.get(urlPath);
  }

  async GetHomeViewModel(request) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/home`;

    return this.httpClient.post(urlPath, request);
  }

  async GetNewHomeViewModel(request) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/new-home`;

    return this.httpClient.post(urlPath, request);
  }

  async GetCalendarVision(request) {
    const urlPath = `/appointments/get-calendar-vision`;

    console.log(urlPath);
    console.log(request);

    return this.httpClient.post(urlPath, request);
  }

  async getAppointment(id) {
    const urlPath = `/appointments/get/${id}`;

    return this.httpClient.get(urlPath);
  }

  // async createAppointment(appointment) {
  //   const urlPath = '/appointments';

  //   return this.httpClient.post(urlPath, appointment);
  // }

  // async updateAppointment(appointment) {
  //   const urlPath = '/appointments/update';

  //   return this.httpClient.post(urlPath, appointment);
  // }

  async createAppointmentFormData(appointment) {
    const urlPath = '/appointments';

    return this.httpClient.postFormData(urlPath, appointment);
  }

  async updateAppointmentFormData(appointment) {
    const urlPath = '/appointments/update';

    return this.httpClient.postFormData(urlPath, appointment);
  }

  // async updateAppointmentAtachmentFormData(appointment) {
  //   const urlPath = '/appointments/update-attachment';

  //   return this.httpClient.postFormData(urlPath, appointment);
  // }

  async updateAppointmentAtachmentFormData(appointmentAttachment) {
    // const urlPath = '/appointmentfiles/update-attachment';
    const urlPath = '/appointments/update-attachment';

    return this.httpClient.postFormDataAxios(urlPath, appointmentAttachment);
    // return this.httpClient.postFormData(urlPath, appointmentAttachment);
  }

  async deleteAppointment(appointment) {
    const urlPath = '/appointments/delete';

    return this.httpClient.post(urlPath, appointment);
  }
  async duplicate(appointment) {
    const urlPath = '/appointments/duplicate';

    return this.httpClient.post(urlPath, appointment);
  }

  async deleteAppointmentAttachment(appointment) {
    const urlPath = '/appointments/delete-attachment';

    return this.httpClient.post(urlPath, appointment);
  }

  async markAppointmentNotificationAsRead(request) {
    const urlPath = `/appointments/mark-appointment-as-read`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }
}

export default AppointmentsService;